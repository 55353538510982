var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"promotion-list"}},[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-text-field',{staticClass:"promotion-list-search me-3",attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","placeholder":"Поиск..."},model:{value:(_vm.filters.searchQuery),callback:function ($$v) {_vm.$set(_vm.filters, "searchQuery", $$v)},expression:"filters.searchQuery"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.feedback,"server-items-length":_vm.totalCount,"loading":_vm.loading,"show-expand":"","hide-default-footer":true,"expanded":_vm.expanded,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50]
    },"page":_vm.filters.pageNumber,"items-per-page":_vm.filters.pageSize,"sort-by":_vm.filters.sortBy,"sort-desc":_vm.filters.sortDesc},on:{"update:expanded":function($event){_vm.expanded=$event},"update:page":function($event){return _vm.$set(_vm.filters, "pageNumber", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.filters, "pageSize", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.filters, "pageSize", $event)},"update:sortBy":function($event){return _vm.$set(_vm.filters, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.filters, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.filters, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.filters, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"item.initials",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.initials)+" ")]}},{key:"item.phoneNumber",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.phoneNumber)+" ")]}},{key:"item.message",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"truncate"},[_vm._v(_vm._s(item.message))])]}},{key:"item.creationTime",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toTimeSting(item.creationTime))+" ")]}},{key:"item.deviceType",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"rounded-0",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getIcon(item.deviceType))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.deviceInformation))])])]}},{key:"expanded-item",fn:function(ref){
    var item = ref.item;
    var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"box"},[_c('p',[_vm._v(" "+_vm._s(item.message)+" ")])])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }