<template>
  <v-card id="promotion-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5"></div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filters.searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Поиск..."
          class="promotion-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="feedback"
      :server-items-length="totalCount"
      :loading="loading"
      show-expand
      :hide-default-footer="true"
      :expanded.sync="expanded"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50]
      }"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
    >

      <!-- name -->
      <template #[`item.initials`]="{item}">
        {{ item.initials }}
      </template>

      <template #[`item.phoneNumber`]="{item}">
        {{ item.phoneNumber }}
      </template>

      <template #[`item.message`]="{item}">
        <div class="truncate">{{ item.message }}</div>
      </template>

      <template #[`item.creationTime`]="{item}">
        {{ toTimeSting(item.creationTime) }}
      </template>

      <template #[`item.deviceType`]="{item}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon size="20"
                    v-bind="attrs"
                    v-on="on"
                    class="rounded-0">
              {{ getIcon(item.deviceType) }}
            </v-icon>
          </template>
          <span>{{ item.deviceInformation }}</span>
        </v-tooltip>
      </template>

      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <div class="box">
            <p> {{ item.message }} </p>
          </div>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import { toDateTimeString } from '@/helpers'
import {
  mdiAppleIos,
  mdiAndroid,
  mdiMonitor,
} from '@mdi/js'

export default {
  name: 'FeedbackList',
  data: () => ({
    icons: {
      mdiAppleIos,
      mdiAndroid,
      mdiMonitor,
    },
    expanded: [],
  }),
  computed: {
    ...mapFormHandlers('feedback', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc',
    ]),
    ...mapState('feedback', {
      feedback: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'ФИО', value: 'initials' },
        { text: 'Телефон', value: 'phoneNumber' },
        { text: 'Сообщение', value: 'message', width: '33vw' },
        { text: 'Дата', value: 'creationTime' },
        { text: 'Устройство', value: 'deviceType' },
      ]
    },
  },
  async created() {
    document.title = 'Обратная связь'
    this.fetchFeedback()
  },
  mounted() {
  },
  methods: {
    ...mapActions('feedback', {
      fetchFeedback: 'fetchList',
    }),
    toTimeSting(val) {
      return toDateTimeString(val)
    },
    getIcon(val) {
      switch (val) {
        case 'Android':
          return this.icons.mdiAndroid
        case 'iOS':
          return this.icons.mdiAppleIos
      }
      return this.icons.mdiMonitor
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}

.truncate {
  max-width: 33vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.box {
  width: 90vw;
}

</style>
